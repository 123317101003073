import { navigate, Link } from "gatsby";
import React, { useEffect, useState } from "react";
// import logo from "../../../assets/MentorAmpLogo.png";
import logo from "../assets/deepen-logo-black.png";
import {
  RealmAppProvider,
  useRealmApp,
  APP_ID,
  OnboardingRedirector,
} from "../../../components/realm_app";
import WelcomeHeader from "../../../components/welcome_header";

function sleep(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

const EmailConfirmedPage = ({ location }) => {
  const realmApp = useRealmApp();

  const [status, setStatus] = useState(<div>"confirming your email..."</div>);

  const invalidUrlDiv = (
    <div>
      Invalid/expired confirmation url. Make sure to click on the confirmation
      email link in your email inbox, or &nbsp;
      <Link to="/facilitator/login/">resend confirmation email.</Link>
    </div>
  );

  async function confirmUser() {
    if (typeof window !== "undefined") {
      const searchParams = new URL(location.href).searchParams;
      const token = searchParams.get("token");
      const tokenId = searchParams.get("tokenId");
      if (token != null && tokenId != null) {
        try {
          await realmApp.app.emailPasswordAuth.confirmUser(token, tokenId);
          setStatus(
            <div>
              "Email confirmed, redirecting you to login page in 5 seconds..."
            </div>
          );
          await sleep(5000);
          navigate("/facilitator/login");
        } catch (err) {
          console.log(err);
          console.log(JSON.stringify(err));
          setStatus(invalidUrlDiv);
        }
      } else {
        setStatus(invalidUrlDiv);
      }
    } else {
      setStatus(<div>"Page load error. Please refresh to try again."</div>);
    }
  }

  useEffect(() => {
    confirmUser();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <WelcomeHeader link={false} />
      <div>{status}</div>
      <br />
      <Link to="/">Home</Link>
    </div>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      {/* <OnboardingRedirector location={location}> */}
      <EmailConfirmedPage location={location} />
      {/* </OnboardingRedirector> */}
    </RealmAppProvider>
  );
};
